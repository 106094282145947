import { render, staticRenderFns } from "./RadioTiles.vue?vue&type=template&id=637f8ff8&"
import script from "./RadioTiles.vue?vue&type=script&lang=js&"
export * from "./RadioTiles.vue?vue&type=script&lang=js&"
import style0 from "./RadioTiles.vue?vue&type=style&index=0&lang=less&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VItem } from 'vuetify/lib/components/VItemGroup';
import { VItemGroup } from 'vuetify/lib/components/VItemGroup';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCard,VCol,VItem,VItemGroup,VRow})
