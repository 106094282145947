<template>
	<div>
		<v-item-group v-model="selected" :multiple="multiple" :disabled="disabled">
			<v-row>
				<v-col v-for="option in options" :key="option" :cols="colSizeXs" :sm="colSizeSm" :lg="colSize"  class="ig-option-tile-wrapper">
					<v-item v-slot="{ active, toggle }" :value="answerOptions[option].value">
						<v-card :class="active ? 'active' : ''" class="ig-option-tile" @click="disabled ? warn() : toggle()">
							{{answerOptions[option].text}}
						</v-card>
					</v-item>
					<AnswerOptionPollResults  v-if="poll" :answer="answerOptions[option].value" :poll="poll"></AnswerOptionPollResults>
				</v-col>
			</v-row>
		</v-item-group>
	</div>
</template>

<style lang="less">
	@import '../../../../src/assets/css/survey/answerOptions.less';	
</style>

<script type="text/javascript">
	import Vuex from "vuex";
	import AnswerOptionPollResults from "@/components/survey/poll/AnswerOptionPollResults"
	export default {
		name: "RadioTiles", 
		props: {
			id: {},
			question: {},
			data: {},
			disabled: {},
			multiple: {type: Boolean, default: false },
			poll: {},
		},
		data: () => {
			return {
				selected: false, 
			}
		},
		components: {
			AnswerOptionPollResults
		},
		computed: {
			...Vuex.mapState({
				answerOptions(state){
					return state.questions[this.id].answerOptions || {}
				}
			}),
			options(){
				return this.mwutilities.getPositions(this.answerOptions);
			},
			answerCount(){
				return this.options.length;
			},
			rows(){
				// TODO: this should be a settable question option later
				var maxColsPerRow = this.question.tilesPerRow || 6; 
				return Math.ceil(this.answerCount / maxColsPerRow);
			},
			colSize(){				
				var space = (12 * this.rows)/ this.answerCount;
				var cols = Math.floor(space);
				return cols
			},
			colSizeSm(){
				return 4
			}, 
			colSizeXs(){
				return 6
			}, 
		},
		watch: {
			selected(){
				this.$emit("update", this.selected )
			}, 
			data: {
				immediate: true, 
				handler(data){
					this.selected = data
				}
			}
		},
		methods: {
			warn(){
				this.$emit("error")
			}
		},
		created(){
			this.$store.dispatch("questions/fetchAnswerOptions", this.id )
		},		
		destroyed(){
			this.$store.dispatch("detachOne", this.id+"-answers")
		},


	}
// </script>"