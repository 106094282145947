<template>
	<div class="text-center mt-2 poll-results">
		<PollResponseAvatar v-for="id in responded" :key="id" :id="id">
		</PollResponseAvatar>
	</div>
</template>

<style lang="less">
	.poll-results{
		min-height: 30px;
		.v-avatar {
			color: white;
		}
	}
</style>

<script type="text/javascript">
	// import Vuex from "vuex";
	import PollResponseAvatar from "@/components/survey/poll/PollResponseAvatar"
	export default {
		name: "AnswerOptionPollResults", 
		props: {	
			poll: {}, 
			answer: {}
		},
		data: () => {
			return {

			}
		},
		components: {
			PollResponseAvatar
		},
		computed: {
			polls() {
				return this.$store.getters.currentPolls
			},
			responded(){
				var poll = this.poll;
				var answer = this.answer;
				return Object.keys(poll).filter( a => poll[a] == answer )
			}
			// ...Vuex.mapState({})
		},
		
		// created(){
			
		// }

	}
// </script>"